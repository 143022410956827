<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row class="mb-2">
        <b-col md="6">
          <h5 class="text-uppercase heading">Users</h5>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col md="1">
          <!-- Select User Per Page  -->
          <b-form-select v-model="item_per_page" @change="changeItemNumber">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="50">50</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col md="8">
          <!-- Filter Options for project, plot and zone level -->
          <b-form enctype="multipart/form-data">
            <b-row>
              <b-col md="4">
                <b-form-group id="input-group-2" label-for="input-2">
                  <v-select
                    label="name"
                    v-model="form.project_id"
                    class="vue-select2"
                    name="select2"
                    :options="projects"
                    :searchable="true"
                    @input="projectChange"
                    placeholder="Enter Project"
                    :reduce="(project) => project._id"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="input-group-2" label-for="input-2">
                  <v-select
                    label="code"
                    v-model="form.zone_id"
                    class="vue-select2"
                    name="select2"
                    :options="zones"
                    :searchable="true"
                    placeholder="Enter Zone"
                    @change="zoneChange"
                    :reduce="(zone) => zone._id"
                    :disabled="form.project_id == ''"
                    multiple
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="input-group-2" label-for="input-2">
                  <v-select
                    label="code"
                    v-model="form.plot_id"
                    class="vue-select2"
                    name="select2"
                    :options="plots"
                    :searchable="true"
                    placeholder="Enter Plot"
                    :reduce="(plot) => plot._id"
                    :disabled="form.project_id == ''"
                    multiple
                  >
                    <template slot="option" slot-scope="option">
                      {{ option._zoneId.code + ' - ' + option.code }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col md="2" class="text-right">
          <b-button variant="primary" @click="FilterUsers" block
            >Search</b-button
          >
        </b-col>
        <b-col md="1" class="d-flex align-items-start justify-content-end">
          <b-button variant="success" @click="downloadCSV">
            <i class="fa fa-download" aria-hidden="true"></i>
          </b-button>
        </b-col>

      </b-row>
      <b-table
        id="my-table"
        class="dataTable rounded-left"
        :outlined="true"
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        head-variant="none"
        table-variant="none"
        :busy="isBusy"
      >
        <template #cell(#)="data">
          <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
          <span v-else>{{
            (currentPage - 1) * item_per_page + (data.index + 1)
          }}</span>
        </template>
        <template #cell(name)="data">
          <span v-if="data.item.name.length > 40">
            {{ data.item.name.substring(0, 40) }}
          </span>
          <span v-else>
            {{ data.item.name }}
          </span>
        </template>
        <template #cell(email)="data">
          <span v-if="data.item.email.length > 40">
            {{ data.item.email.substring(0, 40) }}
          </span>
          <span v-else>
            {{ data.item.email }}
          </span>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(member_since)="data">
          {{ data.item.member_since | moment('DD-MM-YYYY') }}
        </template>

        <template #cell(dob)="data">
          {{ data.item.dob | moment('DD-MM-YYYY') }}
        </template>

        <template #cell(last_activity)="data">
          {{ data.item.last_activity | moment('DD-MM-YYYY') }}
        </template>
        <template #cell(actions)="row">
          <div class="btn-group">
            <router-link :to="'/users/' + row.item._id">
              <i class="fa fa-eye view-icon"></i>
            </router-link>
          
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center mt-4">
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  components: {

  },
  data() {
    return {
      form: {
        zone_id: '',
        plot_id: '',
        project_id: '',
      },
      projects: [],
      plots: [],
      zones: [],
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      isBusy: false,
      sortBy: 'name',
      sortDesc: false,
      fields: [
        '#',
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'phone',
          label: 'Phone',
        },
        {
          key: 'dob',
          label: 'Date of Birth',
          sortable: true,
        },
        {
          key: 'member_since',
          label: 'Member Since',
          sortable: true,
        },
        {
          key: 'last_activity',
          label: 'Last Activity',
          sortable: true,
        },
        {
          key: 'TotalCredits',
          label: 'Total Credits',
          sortable: true,
        },
        {
          key: 'CurrentCredits',
          label: 'Available Credits',
          sortable: true,
        },
        {
          key: '_userTypeID.name',
          label: 'Type',
          sortable: true,
        },
        'actions',
      ],
      items: [],
      options: [],
      query: {
        name: '',
        email: '',
        phone: '',
        page: 1,
      },
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.fetchData();
    this.getUserType();
    this.getProjects();
  },
  methods: {
    downloadCSV() {
      let csv =
        '\ufeff' +
        'NAME,EMAIL,PHONE,DATE OF BIRTH, MEMBER SINCE, LAST ACTIVITY, TOTAL CREDITS, AVILABLE CREDITS, TYPE\n';
      this.items.forEach((el) => {
        var line =
          el['name'] +
          ',' +
          el['email'] +
          ',' +
          el['phone'] +
          ',' +
          `${el['dob'] ? moment(el['dob']).format('DD-MM-YYYY') : null}` +
          ',' +
          `${
            el['member_since']
              ? moment(el['member_since']).format('DD-MM-YYYY')
              : null
          }` +
          ',' +
          `${
            el['last_activity']
              ? moment(el['last_activity']).format('DD-MM-YYYY')
              : null
          }` +
          ',' +
          el['TotalCredits'] +
          ',' +
          el['CurrentCredits'] +
          ',' +
          el['_userTypeID'].name +
          '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Users.csv';
      link.click();
    },

    FilterUsers() {
      console.log(this.form.plot_id);
      console.log(this.form.zone_id);
      this.isBusy = true;
      window.axios
        .get('users', {
          params: this.form,
        })
        .then((resp) => {
          console.log(resp.data.data);
          this.items = resp.data.data;
          this.isBusy = false;
        });
    },

    getProjects() {
      window.axios.get('project').then((resp) => {
        this.projects = resp.data.data;
      });
    },

    getUserType() {
      window.axios.get('user-types').then((resp) => {
        this.options = resp.data;
      });
    },
    changeItemNumber() {
      this.perPage = this.item_per_page;
    },
    userListReload() {
      this.fetchData();
    },
    fetchData() {
      this.isBusy = true;
      this.$store
        .dispatch('fetchData', this.query)
        .then((res) => {
          this.isBusy = false;
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    projectChange() {
      (this.zones = []), (this.plots = []);
      this.form.zone_id = '';
      this.form.plot_id = '';

      this.selectedProject = this.projects.filter((number) => {
        return number._id == this.form.project_id ? number.name : false;
      });
      window.axios
        .get('/project-zones/' + this.form.project_id)
        .then((resp) => {
          this.zones = resp.data.data;
        });

      window.axios
        .get('/project-zone-plot/' + this.form.project_id)
        .then((resp) => {
          this.plots = resp.data.data;
        });
    },

    zoneChange() {
      this.selectedZone = this.zones.filter((number) => {
        return number._id == this.form.zone_id ? number.code : false;
      });
      window.axios
        .get('/project-zone-plot/' + this.form.project_id)
        .then((resp) => {
          this.plots = resp.data.data;
        });
    },
  },
};
</script>

<style scoped>
.heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 3.5%;
  margin-top: 5px;
  margin-left: 2px;
  margin-bottom: 5px;
  color: #454545;
}
.icon {
  margin-top: 45px;
  margin-left: 14.17px;
}
.view-icon {
  color: #1da040;
  height: 20px;
  width: 30px;
  margin-top: 3px;
}
/deep/ .dataTable {
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable thead {
  background-color: #dcffd9;
  height: 64px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #303030;
}
/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
